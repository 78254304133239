import { defineStore } from 'pinia'
import type { SearchFilterGroupModel, SearchFilterModel } from '~/composables/useSearchFilters'

export const useRawSearchFilters = defineStore('rawSearchFilters', {
  state: () => ({
    filters: undefined as Record<string, SearchFilterModel> | undefined,
    all: undefined as SearchFilterGroupModel[] | undefined,
    general: undefined as SearchFilterGroupModel | undefined,
  }),
})
