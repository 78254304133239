<template>
    <v-app>
        <app-header />
        <app-background />
        <v-main>
            <div>
                <v-container
                    fluid
                    class="bg-white page-block"
                >
                    <div class="error-page">
                        <h1 class="error-page__h1">
                            Seems like we can't find the page that you are looking for.
                        </h1>
                        
                        <p class="error-page__p">
                            The page you are looking for might have been removed,
                            had its name changed, or is temporarily unavailable.
                        </p>
                        <NuxtLink
                            to="/"
                            class="m-auto"
                        >
                            <v-btn color="primary" @click="handleError">
                                Back to Home page
                            </v-btn>
                        </NuxtLink>
                    </div>
                </v-container>
            </div>
        </v-main>
        <app-footer />
    </v-app>
</template>
<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
    error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>
<style lang="scss">
.page-enter-active,
.page-leave-active {
    transition: all 0.5s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.error-page {
    &::before {
        background-attachment: local;
        background-position: right;
        position: absolute;
        right: 0;
        
        display: block;
        content: url('/404text.svg');
        min-width: 320px;
        width: 50%;
        opacity: 30%;
    }

    position: relative;
    height: 60dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &__h1 {
        width: 100%;
        font-style: normal;
        font-weight: 800;
        font-size: 1.825rem;
        line-height: 32px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-align: center;

        @media (min-width: 992px) {
            font-size: 2.625rem;
            line-height: 52px;
        }
    }

    &__p {
        margin: 50px 0;
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 28px;
        text-align: center;
    }
}
</style>