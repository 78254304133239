import { defineStore } from 'pinia'

interface IContactInfo {
  contact: { icon: string[]; info: string; link?: string }[]
  socials: { title: string; icon: string[]; link: string }[]
}

export const useContactInfo = defineStore('contactInfo', {
  state: () => (
    {
      info: {
        contact: [
          {
            icon: ['fa', 'phone-flip'],
            info: '786-765-3185',
            link: 'tel:786-765-3185',
          },
        //   {
        //     icon: ['fa', 'envelope'],
        //     info: 'dlemeshev@gmail.com',
        //     link: 'mailto:dlemeshev@gmail.com',
        //   },
          {
            icon: ['fa', 'location-dot'],
            info: '19390 Collins Avenue, Suite B-3\nSunny Isles Beach, FL 33160',
            link: 'https://www.google.com/maps/place/Asset+Realty+Partners/@25.9549362,-80.1249114,17.79z/data=!4m14!1m7!3m6!1s0x88d9ace919c2b93d:0xdfdbe3f074663ca7!2s19390+Collins+Ave+b+3,+Sunny+Isles+Beach,+FL+33160,+%D0%A1%D0%A8%D0%90!3b1!8m2!3d25.9550376!4d-80.1245107!3m5!1s0x88d9ace9190f0a5b:0xc228408fcadda0a!8m2!3d25.954857!4d-80.124489!16s%2Fg%2F11g0k0q536?entry=ttu',
          },
        ],
        socials: [
          {
            title: 'Realtor',
            link: 'https://www.realtor.com/realestateagents/5682b5ff89a68901006ba5fd',
            icon: ['fa-solid', 'fa-house'],
          },
          {
            title: 'Facebook',
            link: 'https://www.facebook.com/profile.php?id=100063652359434',
            icon: ['fab', 'facebook-f'],
          },
          {
            title: 'LinkedIn',
            link: 'https://www.linkedin.com/in/danny-lemeshev-a6472039',
            icon: ['fab', 'linkedin-in'],
          },
        ],
      } as IContactInfo,
    }),
  actions: {
    setQuery(info: IContactInfo) {
      this.info = info
    },
  },
})
