import { config, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faFacebookF,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import {
  faBars,
  faBath,
  faBed,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
  faEnvelope,
  faHeart,
  faHouse,
  faLocationDot,
  faMap,
  faPhoneFlip,
  faRulerCombined,
  faSearch,
  faShare,
  faSink,
  faSliders,
  faSquareCheck,
  faTableColumns,
  faVectorSquare,
  faWarehouse,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare,
  faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons'

const icons = {
  faFacebookF,
  faLinkedinIn,
  faHouse,
  faHeart,
  farHeart,
  faRulerCombined,
  faShare,
  faBath,
  faBed,
  faCircleXmark,
  faSink,
  faChevronRight,
  faChevronLeft,
  faEnvelope,
  faLocationDot,
  faPhoneFlip,
  faCaretDown,
  faBars,
  faMap,
  faSliders,
  faSearch,
  faXmark,
  faWarehouse,
  faVectorSquare,
  faTableColumns,
  faSquare,
  faSquareCheck,
}

export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    library.add(icons)

    config.autoAddCss = false

    nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
  },
})
