import { parseSearchFilters } from './useSearchFilters'
import { useAppliedSearchFilters } from '~/stores/appliedSearchFilters'
import type { LocationQuery } from '#vue-router'
import { useRawSearchFilters } from '~/stores/rawSearchFilters'

const updating = ref(false)

function readQuery() {
  const route = useRoute()

  if (!route.meta.isSearchPage)
    return

  const appliedFilters = useAppliedSearchFilters()
  const query = { ...(route.query) }

  appliedFilters.page = query.page ? Number.parseInt(query.page as string) : 1
  appliedFilters.text = (query.text ?? '') as string

  delete query.page
  delete query.text

  appliedFilters.filters = parseSearchFilters(useRawSearchFilters().filters, query)
  appliedFilters.hasChanges = true
}

async function updateQuery() {
  updating.value = true

  const appliedFilters = useAppliedSearchFilters()
  const query = { ...appliedFilters.filters } as Record<string, any>

  query.text = appliedFilters.text
  query.page = appliedFilters.page

  await useRouter().push({ query: query as LocationQuery, force: false })

  updating.value = false
}

export function useSearchQueries() {
  return { readQuery, updateQuery }
}
