<template>
    <div>
        <v-footer>
            <v-container
                fluid
                class="page-block"
            >
                <v-row
                    justify="space-between"
                    class="h-content"
                    no-gutters
                >
                    <v-col
                        cols="12"
                        sm="6"
                        md="auto"
                        class="h-content py-4"
                    >
                        <app-logo class="mx-auto mx-sm-0" />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="auto"
                        class="d-flex flex-column py-4 gap-2"
                    >
                        <v-label
                            class="pb-2 mx-auto mx-sm-0"
                            text="Contact Us"
                        />
                        <contact-info
                            :display-contact-button="true"
                            vertical
                            class="d-flex flex-column gap-2 align-center align-sm-start"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="auto"
                        class="d-flex flex-column py-4 gap-2 d-print-none"
                    >
                        <v-label
                            class="pb-2 mx-auto mx-sm-0"
                            text="Additional pages"
                        />
                        <nuxt-link
                            to="/privacy-policy"
                            class="text-black mx-auto mx-sm-0 w-content"
                            style="text-decoration: none"
                        >
                            Privacy policy
                        </nuxt-link>
                        <nuxt-link
                            to="/terms-and-conditions"
                            class="text-black mx-auto mx-sm-0 w-content"
                            style="text-decoration: none"
                        >
                            Terms and conditions
                        </nuxt-link>
                    </v-col>
                    <v-col
                        cols="12"
                        md="auto"
                        sm="6"
                        class="h-100 pa-0 ma-0 d-flex flex-column py-4 d-print-none"
                    >
                        <v-label
                            class="pb-4 mx-auto mx-sm-0"
                            text="Follow Us"
                        />
                        <socials-info
                            color="black"
                            class="d-flex flex-row gap-6 justify-center justify-sm-start"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>

<script
    setup
    lang="ts"
>
</script>
