<template>
  <nuxt-link
    to="/"
    class="app-logo d-flex flex-row gap-1"
  >
    <nuxt-img
      src="/logo.png"
      class="app-logo__img"
      height="48px"
      width="34px"
      format="webp"
      quality="100"
      loading="lazy"
      alt=""
    />

    <div class="app-logo__text">
      <span>
        Asset
      </span>
      <span>
        Realty
      </span>
      <span>
        Partners
      </span>
    </div>
  </nuxt-link>
</template>

<style lang="scss" scoped>
.app-logo {
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  color: black;

  text-transform: uppercase;
  text-decoration: none;
  font-weight: 200;

  &__img {
    height: 48px;
    object-fit: contain;
  }

  &__text {

    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    line-height: 1rem;

    font-size: 0.875rem;

    @media (max-width: 375px) {
      font-size: 0.7rem;
    }
  }
}
</style>
