import { defineStore } from 'pinia'
import type { ProductModelMap } from '~/models/productMappings'

export const useProductModelMapping = defineStore('productModelMapping', {
  state: () => ({
    mappings: {
      auto: [],
      special: [],
    } as ProductModelMap,
  }),
})
