<template>
  <v-img
    :src="$img('/global-background.jpg', { height: 1080, quality: 100, format: 'webp' })"
    :lazy-src="$img('/global-background.jpg', { height: 150, quality: 100, format: 'webp' })"
    alt=""
    width="100vw"
    class="w-screen h-screen"
    cover
    style="position: fixed; top: 0; left: 0;"
  />
</template>

<script setup>
</script>
