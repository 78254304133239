import { useAppliedSearchFilters } from '~/stores/appliedSearchFilters'
import { useSearchResults } from '~/stores/searchResults'

const isSearching = ref(false)

export interface SearchResult {
    results: (ListingModel | false)[]
    totalCount: number
    totalPages: number
}

async function performSearch(isPageChanged = false) {
    if (isSearching.value)
        return

    const appliedFilters = useAppliedSearchFilters()
    const { updateQuery } = useSearchQueries()
    if (appliedFilters.hasChanges && !isPageChanged) {
        appliedFilters.page = 1
        await updateQuery()
    }

    isSearching.value = true

    const { text, filters, page } = appliedFilters

    const { data, pending, execute } = await useFetch<SearchResult>('/api/search', {
        lazy: true,
        method: 'post' as const,
        body: { text, filters, page },
        keepalive: false,
        watch: false,
        immediate: false,
    })

    watch(pending, (p) => {
        if (!p)
            isSearching.value = false
    })

    watch(data, (d) => {
        const searchResults = useSearchResults()

        if (!d) {
            searchResults.valid = false
            searchResults.results = [false, false, false, false]
            searchResults.totalCount = 1
            searchResults.totalPages = 1
        }
        else {
            searchResults.valid = true
            searchResults.results = d.results
            searchResults.totalCount = d.totalCount
            searchResults.totalPages = d.totalPages
            searchResults.isInitialized = true
            appliedFilters.hasChanges = false
        }
    })

    await execute()
}

const performDebouncedSearch = useDebounce(performSearch, 1000)

export const useSearch = () => ({ performSearch, performDebouncedSearch, isSearching })
