<template>
    <div>
        <v-app-bar
            flat
            class="app-header pa-0 ma-0 w-screen print-static"
            :height="$vuetify.display.mdAndUp ? 126 : 106"
        >
            <v-container
                fluid
                class="ma-0 pa-0"
            >
                <v-container
                    fluid
                    class="app-header__upper-nav-panel page-block text-white ma-0 pa-0"
                >
                    <v-row
                        class="ma-0 pa-0 mx-auto flex-nowrap h-100"
                        justify="center"
                        justify-sm="space-between"
                    >
                        <v-col
                            cols="auto"
                            class="h-100 pa-0 ma-0"
                        >
                            <contact-info
                                class="d-flex flex-row align-center gap-15 header-contacts"
                                color="white"
                                hide-items
                            />
                        </v-col>
                        <v-spacer
                            class="d-sm-none"
                            style="max-width: 100px"
                        />
                        <v-col
                            cols="auto"
                            class="h-100 pa-0 ma-0"
                        >
                            <socials-info
                                class="d-flex flex-row align-center gap-6"
                                color="white"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    fluid
                    class="app-header__lower-nav-panel page-block"
                    :style="`height: ${$vuetify.display.mdAndUp ? 90 : 70}px !important`"
                >
                    <v-row
                        align="center"
                        no-gutters
                        class="pa-0 ma-0 h-100 mx-auto flex-nowrap"
                        justify="space-between"
                    >
                        <v-col cols="auto">
                            <app-logo />
                        </v-col>
                        <v-col
                            cols="auto"
                            class="nav-buttons d-none d-md-block"
                        >
                            <v-btn
                                v-for="item in menuItems"
                                :key="item.title"
                                :color="item.color"
                                style="text-transform: none; font-weight: 400; font-size: 1rem;"
                                variant="text"
                                :to="item.url"
                                :text="item.title"
                            />
                        </v-col>
                        <v-col
                            style="min-width: 200px; max-width: 450px; flex-basis: 150px"
                            class="flex-shrink-1 flex-grow-1 ps-4 d-print-none"
                        >
                            <v-form
                                class="w-100"
                                @submit.prevent="search"
                            >
                                <v-text-field
                                    id="search-field"
                                    v-model="appliedFilters.text"
                                    autocomplete="off"
                                    hide-details
                                    class="search-bar ma-2 mx-auto w-100"
                                    variant="outlined"
                                    label="Address, city, building name, ZIP"
                                    density="compact"
                                    append-inner-icon="search"
                                    :loading="isSuggestionsLoading ? 'primary' : undefined"
                                    :items="suggestions"
                                    clearable
                                    @click:append-inner.prevent="search"
                                    @update:model-value="updateSearch"
                                >
                                    <v-menu
                                        v-if="!isSearchPage"
                                        activator="parent"
                                        eager
                                        :style="suggestions?.length ? '' : 'display: none'"
                                    >
                                        <v-list>
                                            <v-list-item
                                                v-for="(item, index) in suggestions"
                                                :key="index"
                                                :value="item"
                                                :to="`/buy/${item.data.Property.PropertyType}/${item.data.Property.Location.City}/${item.data.Property.PropertySubType}/${item.slug}`"
                                            >
                                                <v-list-item-title>{{ item.data.Property.Structure.BuildingName }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ `${item.data.Property.Location.City}, ${item.data.Property.Location.StateOrProvince.slice(0, 2).toUpperCase()} ${item.data.Property.Location.PostalCode}` }}</v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-text-field>
                            </v-form>
                        </v-col>
                        <v-col
                            cols="auto"
                            class="d-block d-md-none ma-0 pa-0 nav-activator d-print-none"
                            style="min-width: 40px !important"
                        >
                            <v-app-bar-nav-icon
                                class="d-block mx-auto rounded"
                                @click="isNavDrawerVisible = !isNavDrawerVisible"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-app-bar>
        <v-navigation-drawer
            v-model="isNavDrawerVisible"
            width="320"
            location="right"
            temporary
        >
            <div class="d-flex flex-column justify-space-between h-100">
                <v-container fluid>
                    <v-row>
                        <v-col
                            cols="12"
                            class="nav-buttons"
                        >
                            <v-btn
                                v-for="item in menuItems"
                                :key="item.title"
                                :color="item.color"
                                style="text-transform: none; font-weight: 400; font-size: 1rem; height: 48px"
                                class="w-100"
                                variant="text"
                                :to="item.url"
                                :text="item.title"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <v-container
                    fluid
                    class=""
                >
                    <v-row
                        class="ma-0 pa-0 mx-auto"
                        justify="start"
                        align="start"
                    >
                        <contact-info vertical class="d-flex flex-column gap-2" />
                    </v-row>
                </v-container>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import type { SuggestedResult } from 'models/suggestions'
import { useDisplay } from 'vuetify'
import { useAppliedSearchFilters } from '~/stores/appliedSearchFilters'

const appliedFilters = useAppliedSearchFilters()

const searchQuery = ref()
const display = useDisplay()

const { updateQuery } = useSearchQueries()
const { performDebouncedSearch } = useSearch()
const isSearchInHeader = ref(true)
const isMenuInHeader = computed(() => display.width.value > 720 && display.mdAndUp.value)

const router = useRouter()
const route = useRoute()

const isSearchPage = computed(() => !!route.meta.isSearchPage)

class MenuItem {
    constructor(title: string, url: string) {
        this.title = title
        this.url = url
    }

    title: string
    url: string
    public get color(): string {
        const segments = route.path.split('/')
        return `/${segments[1]}` === this.url ? 'primary' : 'black'
    }
}

const menuItems = ref([
    new MenuItem('Home', '/'),
    new MenuItem('Buy', '/buy'),
    new MenuItem('Sell', '/sell'),
    new MenuItem('Invest', '/invest'),
    new MenuItem('About us', '/about-us'),
])
const isNavDrawerVisible = ref(false)

/** @deprecated I don't know what this does */
async function searchBySuggestion(suggestion: string) {
    searchQuery.value = suggestion
    await search()
}

const suggestions = ref<SuggestedResult[]>([])

const debouncedUpdateSuggestions = _.debounce(updateSuggestions, 700)

watch(searchQuery, beginUpdateSuggestions)

async function beginUpdateSuggestions(query: string) {
    if (isSearchPage.value || !searchQuery.value)
        return

    if (!query.length)
        suggestions.value = []

    if (query.length < 3)
        return

    await debouncedUpdateSuggestions(query)
}

async function updateSearch(text: string) {
    if (!isSearchPage.value) {
        await debouncedUpdateSuggestions(text)
        return
    }

    appliedFilters.text = text
    appliedFilters.hasChanges = true
    await updateQuery()
    await performDebouncedSearch()
}

const isSuggestionsLoading = ref(false)

// TODO: reimplement search API
async function updateSuggestions(text: string) {
    isSuggestionsLoading.value = true
    const { execute, data, pending } = await useFetch('/api/search/suggest', {
        lazy: true,
        method: 'post',
        body: { text },
    })

    watch(pending, async (pending) => {
        isSuggestionsLoading.value = pending
        if (pending)
            return

        // suggestions.value = data.value?.map((x: ISearchSuggest) => ({ subtitle: x.title, filter: x.filter, value: x.suggestion })) ?? []
        suggestions.value = data.value.hits
    })

    await execute()
}

async function search() {
    await router.push({ path: '/buy', query: { text: appliedFilters.text, page: 1 } })
}
</script>

<style lang="scss">
.app-header.v-app-bar {
  background-color: transparent;
  backdrop-filter: blur(2px);
}

.app-header .app-header__upper-nav-panel {
  background-color: rgba(0, 0, 0, 85%);
  line-height: 36px;
  font-size: 14px;
  font-weight: 300;
  height: 36px !important;
  padding: 0;
  margin: 0;
}

.app-header .app-header__lower-nav-panel {
  background-color: rgba(255, 255, 255, 85%);
  height: 90px !important;
  padding: 0;
  margin: 0;
}

.app-nav-drawer {
  width: 320px !important;
}

.app-header.v-app-bar>.v-toolbar__content {
  height: fit-content !important;
}

#search-field {
  min-width: 450px;
}

@media screen and (max-width: 1100px) {
  .app-header.v-app-bar .nav-buttons {
    display: none !important;
  }

  .app-header.v-app-bar .nav-activator {
    display: block !important;
  }

  #search-field {
    min-width: 150px;
  }
}

.v-field__outline__notch {
  width: fit-content;
}

.app-header.v-app-bar .nav-buttons>.v-btn>.v-btn__overlay {
  background-color: transparent;
}

.header-contacts span {
  min-width: -webkit-fit-content;
  min-width: fit-content;
}
</style>
