<template>
    <div>
        <v-btn v-if="displayContactButton" to="/about-us" variant="elevated" color="primary" class="w-100 d-print-none">
            Contact now
        </v-btn>
        <nuxt-link
            v-for="{ info, icon, link }, i in contactInfo.info.contact"
            :key="i"
            :href="link"
            style="text-decoration: none"
            :class="[`text-${color}`, { 'd-none': hideItems && i, 'd-md-inline': hideItems && i }]"
            target="_blank"
        >
            <div
                class="d-flex flex-row align-center gap-3"
                style="vertical-align: middle; white-space: pre-line;"
            >
                <font-awesome-icon
                    :icon="icon"
                    size="lg"
                />
                <div
                    v-if="vertical"
                    v-text="info"
                />
                <span
                    v-else
                    v-text="info.replace('\n', ' ')"
                />
            </div>
        </nuxt-link>
    </div>
</template>

<script
    setup
    lang="ts"
>
import { useContactInfo } from '~/stores/contactInfo'

withDefaults(defineProps<{ vertical?: boolean; hideItems?: boolean; color?: string; displayContactButton?: boolean }>(), {
    vertical: false,
    hideItems: false,
    color: 'black',
    displayContactButton: false,
})
const contactInfo = useContactInfo()
</script>

<style lang="scss"></style>
