import { defineStore } from 'pinia'
import type { SearchResult } from '~/composables/useSearch'

export const useSearchResults = defineStore('appliedSearchFilters', {
    state: () => ({
        results: [false, false, false, false],
        totalCount: 1,
        totalPages: 1,
        isInitialized: false,
        valid: true
    } as SearchResult & { isInitialized: boolean, valid: boolean }),
})
