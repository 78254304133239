<template>
  <span class="range-box">
    <div class="range-box__label-container">
      <v-label v-text="label" />
    </div>
    <VTextField
      v-if="mode !== 'min'"
      hide-details
      :variant="variant"
      type="text"
      :density="density"
      validate-on="input"
      class="d-inline-block ma-0 pa-0"
      :class="mode === 'double' ? ['v-field__min-value', 'w-50', 'rounded-e-0'] : ''"
      :model-value="minValue"
      :rules="[(v) => validateField(0, v)]"
      @change="async (e: any) => await handleChange(e.target.value, 0)"
    />
    <VTextField
      v-if="mode !== 'max'"
      :color="color"
      hide-details
      type="text"
      :variant="variant"
      :density="density"
      validate-on="input"
      class="d-inline-block ma-0 pa-0"
      :class="mode === 'double' ? [`v-field__max-value`, 'w-50', 'rounded-s-0'] : ''"
      :model-value="maxValue"
      :rules="[(v) => validateField(1, v)]"
      @change="async (e: any) => await handleChange(e.target.value, 1)"
    />

    <component
      :is="mode === 'double' ? VRangeSlider : VSlider"
      :model-value="sliderModel"
      v-bind="{
        trackSize,
        color,
        thumbSize,
        min: limits[0],
        max: limits[1],
        step: sliderStep,
        reverse: mode === 'min',
        class: 'slider',
      }"
      hide-details
      @update:model-value="onSliderUpdate"
    />
  </span>
</template>

<script lang="ts">
export type RangeSelectionMode = 'min' | 'max' | 'double'
export type RangeValueType = 'int' | 'float'

export interface ISliderBoxProps {
  mode?: RangeSelectionMode
  type?: RangeValueType
  step?: string | number
  label?: string
  limits?: Array<number>
  variant?: 'filled' | 'outlined' | 'plain' | 'underlined' | 'solo'
  density?: 'default' | 'comfortable' | 'compact'
  modelValue?: Array<number>
  trackSize?: number | string
  thumbSize?: number | string
  trackColor?: string
  color?: string
}
</script>

<script setup lang="ts">
import _ from 'lodash'
import { VRangeSlider, VSlider, VTextField } from 'vuetify/components'

const props = withDefaults(defineProps<ISliderBoxProps>(),
  {
    mode: 'max',
    type: 'int',
    label: '',
    limits: () => [0, 100],
    trackSize: 1,
    thumbSize: 16,
    trackColor: 'transparent',
  })

const emit = defineEmits<{
  (e: 'update:modelValue', values: Array<number>): void
}>()

function onSliderUpdate(value: number | number[]) {
  switch (props.mode) {
    case 'double':
      doubleSliderRange.value = value as number[]
      break
    case 'min':
      reverseMaxValue.value = value as number
      break
    case 'max':
      minValue.value = value as number
      break
    default:
      break
  }
}

const sliderModel = computed(() => {
  switch (props.mode) {
    case 'double': return doubleSliderRange.value
    case 'min': return reverseMaxValue.value
    case 'max': return minValue.value
    default: return minValue.value
  }
})

const range = ref(props.modelValue ?? [...props.limits])

const sliderStep = props.type === 'int' ? _.ceil((props.limits[1] - props.limits[0]) / 100) : undefined

// min and max values that are set according to limits
const minValue = computed({
  get: () => range.value[0],
  set: (v: number) => {
    const newValue = _.clamp(v, props.limits[0], props.limits[1])
    if (newValue === range.value[0])
      return

    range.value[0] = newValue
    emit('update:modelValue', [...range.value])
  },
})

const maxValue = computed({
  get: () => range.value[1],
  set: (v: number) => {
    const newValue = _.clamp(v, props.limits[0], props.limits[1])
    if (newValue === range.value[1])
      return

    range.value[1] = newValue
    emit('update:modelValue', [...range.value])
  },
})

const doubleSliderRange = computed({
  get: () => range.value,
  set: (v) => {
    minValue.value = _.min(v)!
    maxValue.value = _.max(v)!
  },
})

const reverseMaxValue = computed({
  get: () => props.limits[1] - maxValue.value + props.limits[0],
  set: (v: number) => maxValue.value = props.limits[1] - v + props.limits[0],
})

const parsers: Record<string, ((str: string) => number)> = {
  float: (str: string) => Number.parseFloat(str) as number,
  int: (str: string) => Number.parseInt(str) as number,
}

function getDoubleCellLimits(cellId: number): { lower: number; upper: number } {
  // MaxBox: minValue <= newValue <= props.max
  // MinBox: props.min <= newValue <= maxValue
  const oppositeCellId = (cellId + 1) % 2
  const isMaxBox = oppositeCellId < cellId

  // if MaxBox then lower is minValue and upper is props.limits[1]
  // if MinBox then lower is props.limits[0] and upper is maxValue
  const lower = isMaxBox ? minValue.value : props.limits[0]
  const upper = isMaxBox ? props.limits[1] : maxValue.value

  return { lower, upper }
}

function getSingleCellLimits(cellId: number) {
  return { lower: props.limits[0], upper: props.limits[1] }
}

function getCellLimits(cellId: number) {
  return (props.mode === 'double' ? getDoubleCellLimits : getSingleCellLimits)(cellId)
}

async function handleChange(input: string, cellId: number) {
  const parseNumber = parsers[props.type]

  const { lower, upper } = getCellLimits(cellId)

  const cell = [minValue, maxValue][cellId]
  cell.value = NaN

  const newValue = parseNumber(input.length ? input : '0')
  const result = _.clamp(newValue, lower, upper)

  await nextTick(() => {
    cell.value = result
  })
}

function validateField(cellId: number, value: number) {
  const { lower, upper } = getCellLimits(cellId)
  return _.inRange(value, lower, upper + 1) || 'err'
}
</script>

<style lang="scss">
.v-field {
  &__min-value &__outline__end {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__max-value &__outline__start {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &__max-value {
    z-index: -100;
  }
}

.range-box {
  white-space: nowrap;
}

.range-box {
  .range-box__label-container {
    left: 12px;
    top: -13px;
    position: relative;
    width: 0;
    height: 0;
    z-index: 10;
    overflow: visible;
  }

  .v-label {
    font-size: 12px;
    font-weight: 400;
    background-color: white;
    color: #696969;
    opacity: 100%;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.slider {
  margin-top: -23px;
  height: 0;
}
</style>
