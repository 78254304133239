import { default as about_45uskO0hF1MsP7Meta } from "/var/www/myassetrealty.com/front/pages/about-us.vue?macro=true";
import { default as _91slug_933mvJXKX66NMeta } from "/var/www/myassetrealty.com/front/pages/buy/[[propertyType]]/[[city]]/[[propertySubType]]/[slug].vue?macro=true";
import { default as indexvjvoy3NMuqMeta } from "/var/www/myassetrealty.com/front/pages/buy/[[propertyType]]/[[city]]/[[propertySubType]]/index.vue?macro=true";
import { default as indextOWYYb8OxuMeta } from "/var/www/myassetrealty.com/front/pages/index.vue?macro=true";
import { default as investE0VeBxTulgMeta } from "/var/www/myassetrealty.com/front/pages/invest.vue?macro=true";
import { default as privacy_45policykC1NnVdm2uMeta } from "/var/www/myassetrealty.com/front/pages/privacy-policy.vue?macro=true";
import { default as sellNsht5cY5zCMeta } from "/var/www/myassetrealty.com/front/pages/sell.vue?macro=true";
import { default as terms_45and_45conditionsIziX1dYJ1TMeta } from "/var/www/myassetrealty.com/front/pages/terms-and-conditions.vue?macro=true";
import { default as thank_45youWt4Dg6292VMeta } from "/var/www/myassetrealty.com/front/pages/thank-you.vue?macro=true";
export default [
  {
    name: about_45uskO0hF1MsP7Meta?.name ?? "about-us",
    path: about_45uskO0hF1MsP7Meta?.path ?? "/about-us",
    meta: about_45uskO0hF1MsP7Meta || {},
    alias: about_45uskO0hF1MsP7Meta?.alias || [],
    redirect: about_45uskO0hF1MsP7Meta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933mvJXKX66NMeta?.name ?? "buy-propertyType-city-propertySubType-slug",
    path: _91slug_933mvJXKX66NMeta?.path ?? "/buy/:propertyType?/:city?/:propertySubType?/:slug()",
    meta: _91slug_933mvJXKX66NMeta || {},
    alias: _91slug_933mvJXKX66NMeta?.alias || [],
    redirect: _91slug_933mvJXKX66NMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/buy/[[propertyType]]/[[city]]/[[propertySubType]]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvjvoy3NMuqMeta?.name ?? "buy-propertyType-city-propertySubType",
    path: indexvjvoy3NMuqMeta?.path ?? "/buy/:propertyType?/:city?/:propertySubType?",
    meta: indexvjvoy3NMuqMeta || {},
    alias: indexvjvoy3NMuqMeta?.alias || [],
    redirect: indexvjvoy3NMuqMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/buy/[[propertyType]]/[[city]]/[[propertySubType]]/index.vue").then(m => m.default || m)
  },
  {
    name: indextOWYYb8OxuMeta?.name ?? "index",
    path: indextOWYYb8OxuMeta?.path ?? "/",
    meta: indextOWYYb8OxuMeta || {},
    alias: indextOWYYb8OxuMeta?.alias || [],
    redirect: indextOWYYb8OxuMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: investE0VeBxTulgMeta?.name ?? "invest",
    path: investE0VeBxTulgMeta?.path ?? "/invest",
    meta: investE0VeBxTulgMeta || {},
    alias: investE0VeBxTulgMeta?.alias || [],
    redirect: investE0VeBxTulgMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/invest.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policykC1NnVdm2uMeta?.name ?? "privacy-policy",
    path: privacy_45policykC1NnVdm2uMeta?.path ?? "/privacy-policy",
    meta: privacy_45policykC1NnVdm2uMeta || {},
    alias: privacy_45policykC1NnVdm2uMeta?.alias || [],
    redirect: privacy_45policykC1NnVdm2uMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: sellNsht5cY5zCMeta?.name ?? "sell",
    path: sellNsht5cY5zCMeta?.path ?? "/sell",
    meta: sellNsht5cY5zCMeta || {},
    alias: sellNsht5cY5zCMeta?.alias || [],
    redirect: sellNsht5cY5zCMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/sell.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsIziX1dYJ1TMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsIziX1dYJ1TMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsIziX1dYJ1TMeta || {},
    alias: terms_45and_45conditionsIziX1dYJ1TMeta?.alias || [],
    redirect: terms_45and_45conditionsIziX1dYJ1TMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: thank_45youWt4Dg6292VMeta?.name ?? "thank-you",
    path: thank_45youWt4Dg6292VMeta?.path ?? "/thank-you",
    meta: thank_45youWt4Dg6292VMeta || {},
    alias: thank_45youWt4Dg6292VMeta?.alias || [],
    redirect: thank_45youWt4Dg6292VMeta?.redirect,
    component: () => import("/var/www/myassetrealty.com/front/pages/thank-you.vue").then(m => m.default || m)
  }
]