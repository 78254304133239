<template>
  <div>
    <nuxt-link
      v-for="social, i in contactInfo.info.socials"
      :key="i"
      :class="`text-${color}`"
      style="text-decoration: none"
      :to="social.link"
      target="_blank"
    >
      <font-awesome-icon
        :icon="social.icon"
        size="lg"
      />
    </nuxt-link>
    <v-col
      cols="auto"
      v-text="hostname"
      style="display: none;"
      class="d-print-block"
    />
  </div>
</template>

<script setup lang="ts">
import { useContactInfo } from '~/stores/contactInfo'

withDefaults(defineProps<{ color?: string }>(), {
  color: 'black',
})
const contactInfo = useContactInfo()
let hostname = ref<string>()
onMounted(() => {
  hostname.value = window.location.hostname
})
</script>

<style lang="scss"></style>
