import revive_payload_client_4sVQNw7RlN from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/myassetrealty.com/front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/myassetrealty.com/front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ucMK8VhJjR from "/var/www/myassetrealty.com/front/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import leaflet_runtime_mlkDTJ7gZI from "/var/www/myassetrealty.com/front/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import plugin_tLmAP220nC from "/var/www/myassetrealty.com/front/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/var/www/myassetrealty.com/front/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/myassetrealty.com/front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_storageInitializer_1juQeNudG7 from "/var/www/myassetrealty.com/front/plugins/01.storageInitializer.ts";
import _02_scrollPageToTop_client_Uymc8sWyZE from "/var/www/myassetrealty.com/front/plugins/02.scrollPageToTop.client.ts";
import _03_fontawesome_Z3uI7BzGwI from "/var/www/myassetrealty.com/front/plugins/03.fontawesome.ts";
import _04_vuetify_eO9uF9EXLe from "/var/www/myassetrealty.com/front/plugins/04.vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ucMK8VhJjR,
  leaflet_runtime_mlkDTJ7gZI,
  plugin_tLmAP220nC,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  _01_storageInitializer_1juQeNudG7,
  _02_scrollPageToTop_client_Uymc8sWyZE,
  _03_fontawesome_Z3uI7BzGwI,
  _04_vuetify_eO9uF9EXLe
]