import type { SearchFilterModel } from '~/composables/useSearchFilters'
import { useRawSearchFilters } from '~/stores/rawSearchFilters'
import { useProductModelMapping } from '~/stores/productModelMapping'
import { useSearchResults } from '~/stores/searchResults'

async function initSearchFilters() {
    await useAsyncData('initSearchFilters', async (ctx) => {
        const rawFilters = useRawSearchFilters(ctx!.$pinia)

        const { data: filterList, execute: fetchFilters } = await useFetch(
            '/api/list/filters',
            {
                lazy: true,
                immediate: false,
            })

        watch(filterList, (filters) => {
            if (!filters)
                return

            rawFilters.general = filters.general
            rawFilters.all = filters.all

            // TODO: use only 'all' filters for raw
            rawFilters.filters = Object.fromEntries([
                ...filters.all.flatMap(group => group.filterModels.map(model => [model.name, model])),
                ...filters.general.filterModels.map(model => [model.name, model]),
            ]) as Record<string, SearchFilterModel>
        })

        await fetchFilters()
    })
}

async function initProductModelMappings() {
    await useAsyncData('productModelMappings', async (ctx) => {
        const mappings = useProductModelMapping(ctx!.$pinia)

        const { data, execute: fetchMappings } = await useFetch('/api/list/model-map', {
            lazy: true,
            immediate: false,
        })

        watch(data, (d) => {
            if (d)
                mappings.mappings = d
        })

        await fetchMappings()
    })
}

async function initSearchResults() {
    await useAsyncData('searchResults', async (ctx) => {
        const searchResults = useSearchResults(ctx!.$pinia)

        searchResults.results = [false, false, false, false]
        searchResults.totalCount = 0
        searchResults.totalPages = 0
    })
}

async function initStorages() {
    await initSearchResults()
    await initSearchFilters()
    await initProductModelMappings()
}

export default defineNuxtPlugin({
    parallel: true,
    setup(nuxtApp) {
        nuxtApp.hook('app:beforeMount', initStorages)
    },
})
