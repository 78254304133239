import { VSwitch, VTextField } from 'vuetify/lib/components/index.mjs'
import { ListSelector, RangeBox } from '#components'

type FilterableType = number | string | boolean
type SearchFilterDataType = 'number' | 'string' | 'boolean'
type SearchFilterType = 'single' | 'range' | 'select'

interface SearchFilterModel {
    type: SearchFilterType
    dataType: SearchFilterDataType

    title: string
    name: string
    tooltip?: string | null
    group?: string | null

    attributes?: object | null
    allowedValues?: FilterableType[] | null
    style?: Record<string, any>
}

interface SearchFilterInstance {
    component: unknown
    attributes?: object
    style?: Record<string, any>

    allowedValues?: FilterableType[]
    title: string
    name: string
    tooltip: string

    dataType: SearchFilterDataType
    type: SearchFilterType
    modelValue: Ref<FilterableType | FilterableType[]>
}

interface SearchFilterGroupModel {
    name: string
    filterModels: SearchFilterModel[]
}

interface AppliedFilterModel {
    name: string
    value: FilterableType | FilterableType[]
}

type FilterInitializerRecord<T> = Record<SearchFilterType, Record<SearchFilterDataType, T>>

const defaultComponentAttributeGenerators: FilterInitializerRecord<(filterModel: SearchFilterModel) => object> = {
    single: {
        boolean: (filterModel: SearchFilterModel) => ({}),
        number: (filterModel: SearchFilterModel) => ({}),
        string: (filterModel: SearchFilterModel) => ({}),
    },
    range: {
        boolean: (filterModel: SearchFilterModel) => ({ items: filterModel.allowedValues, multiple: true }),
        number: (filterModel: SearchFilterModel) => ({ mode: 'double', limits: filterModel.allowedValues }),
        string: (filterModel: SearchFilterModel) => ({ items: filterModel.allowedValues, multiple: true }),
    },
    select: {
        boolean: (filterModel: SearchFilterModel) => ({ items: filterModel.allowedValues, multiple: true }),
        number: (filterModel: SearchFilterModel) => ({}),
        string: (filterModel: SearchFilterModel) => ({ items: filterModel.allowedValues, multiple: false }),
    },
}

const modelValueGenerators: FilterInitializerRecord<(allowedValues?: FilterableType[]) => FilterableType | FilterableType[]> = {
    single: {
        boolean: (allowedValues?: FilterableType[]) => (allowedValues ? allowedValues[0] : false),
        number: (allowedValues?: FilterableType[]) => (allowedValues ? allowedValues[0] : 0),
        string: (allowedValues?: FilterableType[]) => (allowedValues ? allowedValues[0] : ''),
    },
    range: {
        boolean: (allowedValues?: FilterableType[]) => ([]),
        number: (allowedValues?: FilterableType[]) => (allowedValues?.map(x => isString(x) ? Number.parseFloat(x) : x) || [0, 1]),
        string: (allowedValues?: FilterableType[]) => ([]),
    },
    select: {
        boolean: (allowedValues?: FilterableType[]) => ([]),
        number: (allowedValues?: FilterableType[]) => ([]),
        string: (allowedValues?: FilterableType[]) => ([]),
    },
}

const modelValueParsers: FilterInitializerRecord<(applied: string | null | (string | null)[]) => FilterableType | FilterableType[]> = {
    single: {
        boolean: (applied: string | null | (string | null)[]) => applied === 'true',
        number: (applied: string | null | (string | null)[]) => Number.parseFloat(applied as string),
        string: (applied: string | null | (string | null)[]) => isArray(applied) ? (applied[0] || '') : (applied || ''),
    },
    range: {
        boolean: (applied: string | null | (string | null)[]) => (applied as string[]).map(x => x === 'true'),
        number: (applied: string | null | (string | null)[]) => (applied as string[]).map(Number.parseFloat),
        string: (applied: string | null | (string | null)[]) => isArray(applied) ? applied.filter(x => !!x) as string[] : applied ? [applied] : [],
    },
    select: {
        boolean: (applied: string | null | (string | null)[]) => isArray(applied) ? applied.filter(x => !!x) as string[] : applied ? [applied] : [],
        number: (applied: string | null | (string | null)[]) => (applied as string[]).map(Number.parseFloat),
        string: (applied: string | null | (string | null)[]) => isArray(applied) ? applied.filter(x => !!x) as string[] : applied ? [applied] : [],
    },
}

const componentTypes: FilterInitializerRecord<unknown> = {
    single: {
        string: VTextField,
        number: VTextField,
        boolean: VSwitch,
    },
    range: {
        string: ListSelector,
        number: RangeBox,
        boolean: ListSelector,
    },
    select: {
        string: ListSelector,
        number: RangeBox,
        boolean: ListSelector,
    },
}

function createFilterInstance(filterModel: SearchFilterModel, applied?: FilterableType | FilterableType[]): SearchFilterInstance {
    const { title, name, tooltip, type, dataType, attributes, style, allowedValues } = filterModel
    const needsParsing = !isUndefined(applied) && (isString(applied) || (isArray(applied) && isString(applied[0])))

    const modelValue = ref<FilterableType | FilterableType[]>()

    const sortedAllowedValues = Array.isArray(allowedValues) ? allowedValues.sort() : allowedValues

    if (needsParsing)
        modelValue.value = modelValueParsers[type][dataType](applied as string | string[])
    else if (isUndefined(applied))
        modelValue.value = modelValueGenerators[type][dataType](sortedAllowedValues ?? undefined)
    else modelValue.value = applied

    const filter = {
        title,
        name,
        tooltip,
        style,
        allowedValues: sortedAllowedValues,
        component: componentTypes[type][dataType],
        attributes: attributes ?? defaultComponentAttributeGenerators[type][dataType](filterModel),
        dataType,
        type,
        modelValue,
    } as SearchFilterInstance

    filter.attributes ??= attributes ?? {}

    return filter
}

function parseSearchFilters(groups: Record<string, SearchFilterModel> | null, values: { [x: string]: null | string | (string | null)[] }) {
    if (!groups)
        return {}

    return Object.fromEntries(Object.entries(values)
        .filter(([_, value]) => !!value)
        .map(([name, value]) => {
            const { dataType, type } = groups[name]
            return [name, modelValueParsers[type][dataType](value)]
        })) as Record<string, FilterableType | FilterableType[]>
}

function useSearchFilters(group?: SearchFilterGroupModel, applied?: Record<string, FilterableType | FilterableType[]>): SearchFilterInstance[] {
    return group?.filterModels?.map(x => createFilterInstance(x, (applied ?? {})[x.name])) ?? [] as SearchFilterInstance[]
}

export { useSearchFilters, parseSearchFilters, FilterableType, SearchFilterDataType, SearchFilterInstance, SearchFilterModel, SearchFilterGroupModel, AppliedFilterModel }
