import { createVuetify } from 'vuetify'

// import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
// import { VBottomSheet } from 'vuetify/labs/VBottomSheet'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'

const vuetifyOptions = {
    ssr: true,
    components: {},
    defaults: {
        VBtn: {
            style: 'font-weight: 300; display: inline-flex',
        },
        VIcon: {
            size: '16',
        },
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        themes: {
            light: {
                colors: {
                    primary: '#c42537',
                    error: '#F04050',
                },
            },
        },
    },
}

export default defineNuxtPlugin(
    {
        parallel: true,
        setup(nuxtApp) {
            const vuetify = createVuetify(vuetifyOptions)

            nuxtApp.vueApp.use(vuetify)
        },
    },
)
