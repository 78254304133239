<template>
  <v-combobox
    class="list-selector"
    :model-value="comboboxModel"
    clearable
    persistent-clear
    placeholder="Select"
    no-data-text="Unavailable"
    style="max-height: 40px !important;"
    @update:model-value="updateModel"
  >
    <template #selection="{ index }">
      <v-tooltip v-if="comboboxModel && isArray(comboboxModel) && comboboxModel.length > 1" :text="tooltipText" location="bottom center">
        <template #activator="{ props: activatorProps }">
          <span v-if="index === 0" v-bind="activatorProps" class="text-truncate" v-text="displayText" />
        </template>
      </v-tooltip>
      <span v-else class="text-truncate" v-text="displayText" />
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
const props = defineProps<{ modelValue?: string | string[] }>()
const emit = defineEmits(['update:modelValue'])

const comboboxModel = ref<string | string[] | undefined>(props.modelValue)

function updateModel(value?: string | string[]) {
  comboboxModel.value = value
  emit('update:modelValue', value)
}

const displayText = computed(() => {
  if (!isArray(comboboxModel.value))
    return comboboxModel.value
  if (comboboxModel.value.length === 1)
    return comboboxModel.value[0]

  return `${comboboxModel.value.length} selected`
})

const tooltipText = computed(() => {
  if (isArray(comboboxModel.value))
    return comboboxModel.value.slice(0, 5).join(', ') + (comboboxModel.value.length > 5 ? ` and ${comboboxModel.value.length - 5} other` : '')

  return ''
})
</script>

<style lang="scss">
.list-selector .v-field__input {
  max-height: 38px;
  overflow: hidden;
}
</style>
