import { defineStore } from 'pinia'
import type { FilterableType } from '~/composables/useSearchFilters'

export const useAppliedSearchFilters = defineStore('appliedSearchFilters', {
  state: () => ({
    filters: {} as Record<string, FilterableType | FilterableType[]>,
    text: '',
    page: 1,
    hasChanges: true,
  }),
})
